
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { ProductForm } from '@/interfaces/components/configurator/ProductForm';
import ChecklistSegmentModule from '@/components/views/productView/ChecklistSegmentModule.vue';
import PriceModule from '@/components/views/project/PriceModule.vue';
import { PriceItem } from '@/interfaces/general/PriceItem';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { PriceInputObject } from '@/interfaces/components/configurator/PriceInputObject';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ConfiguratorValue } from '@/interfaces/components/configurator/ConfiguratorValue';
import { ChecklistSegment } from '@/interfaces/components/configurator/ChecklistSegment';
import { extractDefaultValues, extractUpdateFunctions } from '@/helpers/FormDataHelper';
import { debounce } from 'vue-debounce';
import { determineFieldVisibility, extractUpdateFunctionValues } from '@/helpers/FieldFunctionHelper';
import { UpdateFunctionEntry } from '@/interfaces/components/configurator/UpdateFunctionEntry';
import AddOfferItem from '@/components/views/productView/AddOfferItem.vue';
import OfferItem from '@/models/OfferItem';
import { ChecklistField } from '@/interfaces/components/configurator/ChecklistField';
import JsFunctionRepository from '@/repositories/JsFunctionRepository';
import JsFunction from '@/models/JsFunction';
import { parseAndTransformStringWithDynamicValues, registerJsonLogicFunctions } from '@/helpers/JsonLogicHelper';
import { CheckUnsavedDataMixin } from '@/mixins/CheckUnsavedData';
import ClientRepository from '@/repositories/ClientRepository';
import { UserRepository } from '@/repositories/UserRepository';
import Client from '@/models/Client';
import { mapOfferItemsFormData } from '@/helpers/project/ProductFormVersionHelper';
import PopupNotifications from '@/components/global/popup/PopupNotifications.vue';
import DefaultValueWarnings from '@/components/global/popup/DefaultValueWarnings.vue';
import { PopupNotificationOptions } from '@/interfaces/components/projectNew/PopupNotificationOptions';
import Offer from '@/models/Offer';
import OfferRepository from '@/repositories/OfferRepository';
import { checkForErrorsInFieldsAndReturnState, resetHelperState } from '@/helpers/FormValidationHelper';
import { FormValidationPayload } from '@/interfaces/general/FormValidationPayload.js';
import { ErrorOptions } from '@/interfaces/ErrorOptions';
import { extractInvalidDefaultValues } from '@/helpers/FieldFunctionHelper';
import { defaultValueWarnings as defaultValueWarningsConfig } from '@/interfaces/components/projectNew/DefaultValueWarnings';
import ColorModule from '@/components/global/popup/ColorModule.vue';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import ConfiguratorService from '@/services/ConfiguratorService';

@Component({
    name: 'Product',
    components: {
        AddOfferItem,
        PriceModule,
        ChecklistSegmentModule,
        PopupNotifications,
        DefaultValueWarnings,
        ColorModule,
    },
    mixins: [CheckUnsavedDataMixin],
})
export default class ProductView extends Vue {
    // if wizardData is provided, component is in dimensions wizard and will be custom tailored to that
    @Prop({ default: null }) private wizardData!: any | null;
    @Prop({ default: false }) private shouldOpenDimensionsWizardConfigurator!: boolean;
    @Action('configurator/getSingleProductForm')
    private getSingleProductForm!: (productFormId: string) => void;
    @Action('configurator/addActiveProductForm')
    private addActiveProductForm!: (activeProductForm: ActiveProductFormValueObject) => Promise<number | null>;
    @Getter('configurator/productForm') private productForm!: (productFormId: string) => ProductForm | null;
    @Action('configurator/calculatePrice') private calculatePrice!: ({
        activeProductFormValues,
        productFormId,
    }: PriceInputObject) => Promise<any>;
    @Action('configurator/checkForErrors') private checkForErrors!: ({
        activeProductFormValues,
        productFormId,
    }: any) => Promise<any>;
    @Mutation('configurator/resetActiveProductForms')
    private resetActiveProductForms!: () => void;
    @Action('configurator/updateActiveProductFormValue')
    private updateActiveProductFormValue!: ({
        pId,
        value,
        productFormId,
    }: {
        pId: string;
        value: ConfiguratorValue;
        productFormId: number;
    }) => void;
    @Getter('configurator/activeProductForm')
    private getProductFormValues!: (productFormId: number) => ActiveProductFormValueObject | null;
    private notifications: PopupNotificationOptions[] = [];
    private currentProductForm: ProductForm | null = null;
    private sellingPriceList: PriceItem[] = [];
    private purchasePriceList: PriceItem[] = [];
    private roltekPriceList: PriceItem[] = [];
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private activeProductFormId: null | number = null;
    private updateFunctions: UpdateFunctionEntry[] = [];
    private isEditMode = false;
    private clientId: null | string = null;
    private offerId: null | string = null;
    private pIdFieldErrors = [];
    private hasErrors = false;
    private hasUnsavedData = false;
    private level: number = 2;
    private showAllErrors: boolean = false;
    private showAllErrorsContainer: boolean = false;
    private errorList = [];
    private connection = null;
    private production = false;
    private formFieldErrorsExist = false;
    private projectId = '';
    private showDefaultValueWarningsModal = true;

    private get userGroup() {
        return this.$store.getters['jwtData/currentUserGroup'];
    }
    private offerItemId: string | null = null;

    private debouncedPriceCalculation = debounce((productFormValues: ActiveProductFormValueObject) => {
        this.calculateConfiguratorPrice(productFormValues);
    }, 300);
    private debounceShowingChecklistErrors = debounce(() => {
        this.showChecklistErrors();
    }, 100);
    private debounceTriggerVisibilityFunctions = debounce(() => {
        this.triggerVisibilityFunctions();
    }, 100);
    private debounceTriggerOptionsFunctions = debounce(() => {
        this.triggerOptionsFunctions();
    }, 100);

    private get offerItemHasErrors() {
        if (!this.offerItem) {
            return false;
        }

        return !!this.offerItem?.hasErrors;
    }

    private get clientsPaymentTypeId() {
        if (this.client == null || this.client.paymentType == null) {
            return null;
        }

        return this.client.paymentType.id;
    }

    private get client() {
        if (this.clientId == null) {
            return null;
        }

        return ClientRepository.getById(this.clientId);
    }

    private get offer() {
        if (this.offerId == null) {
            return null;
        }

        return OfferRepository.getOfferById(this.offerId);
    }

    private get offerItem(): OfferItem | null {
        if (this.offerItemId == null || !this.offer) {
            return null;
        }

        const offerItem = this.offer.offerItems.find((offerItem: OfferItem) => {
            return offerItem.id === this.offerItemId;
        });

        if (!offerItem) {
            return null;
        }

        return offerItem;
    }

    private get currencySymbol() {
        return UserRepository.getCurrentUsersCurrencySymbol();
    }

    private get functions() {
        return JsFunctionRepository.getAll();
    }

    private get checklistFieldErrorsExist() {
        return (
            this.pIdFieldErrors.length > 0 ||
            this.checklistErrors.some((checklistError) => checklistError.fieldType === 'abort')
        );
    }

    private get checklistSegments() {
        if (this.currentProductForm == null) {
            return [];
        }

        return this.currentProductForm.checklists.checklistSegments.filter(
            (checklistSegment: ChecklistSegment) => !checklistSegment.isLocked
        );
    }

    private get checklistErrors() {
        if (
            !this.currentProductForm?.checklists?.checklistSegments ||
            this.currentProductForm?.checklists?.checklistSegments.length <= 0
        ) {
            return [];
        }
        return this.currentProductForm.checklists.checklistSegments[0].checklistFields.filter(
            (checklistField: ChecklistField) => {
                return (
                    (checklistField.fieldType === 'warning' || checklistField.fieldType === 'abort') &&
                    this.productFormValues &&
                    determineFieldVisibility(checklistField.checklistVisibleFunction, this.productFormValues)
                );
            }
        );
    }

    private get productFormValues() {
        if (this.activeProductFormId == null) {
            return null;
        }

        return this.getProductFormValues(this.activeProductFormId);
    }

    private get flattenedFields() {
        return this.checklistSegments.map((segment) => segment.checklistFields).flat();
    }

    private get defaultValueWarnings() {
        const warnings = this.$store.getters['configurator/defaultValueWarnings'];

        if (!warnings.length) {
            return null;
        }

        if (warnings?.length > 1) {
            return [
                warnings.find(
                    (warning: defaultValueWarningsConfig) => warning.productFormId === this.activeProductFormId
                ),
            ];
        }

        return [warnings[0]];
    }

    private get isCurrentUserTechnologist() {
        return UserRepository.isCurrentUserTechnologist();
    }

    private setDefaultValueWarnings() {
        const warnings: Array<{}> = extractInvalidDefaultValues(
            this.flattenedFields,
            this.productFormValues,
            this.activeProductFormId
        );

        this.$store.dispatch('configurator/setDefaultValueWarnings', [
            {
                id: this.offerItem?.displayRowNumber ?? this.activeProductFormId,
                warnings,
                productName: this.currentProductForm?.name,
                productId: this.currentProductForm?.id,
                productFormId: this.activeProductFormId,
            },
        ]);
    }

    public switchLevel(level: number) {
        this.level = -1;
        this.$nextTick(() => {
            this.level = level;
        });
    }

    private handleIsConnected(event: any) {
        this.connection = event;
    }

    private handleIsProduction(event: any) {
        this.production = event;
    }

    private triggerVisibilityFunctions() {
        EventBus.$emit(EventBusEvents.triggerVisibilityFunctions, {
            activeProductFormValues: this.productFormValues,
        });
    }

    private triggerOptionsFunctions() {
        EventBus.$emit(EventBusEvents.triggerOptionsFunctions, {
            activeProductFormValues: this.productFormValues,
            activeProductFormId: this.activeProductFormId,
        });
    }

    private async checkForConfiguratorErrors(productFormValues: ActiveProductFormValueObject) {
        // const offerItemId = this.$route.params.offerItemId;

        if (this.currentProductForm == null) {
            return;
        }

        try {
            const errors = await this.checkForErrors({
                activeProductFormValues: productFormValues,
                productFormId: this.currentProductForm.id,
                offerItemId: this.offerItemId,
            });

            this.errorList = errors.errors;

            if (this.errorList.length) {
                this.hasErrors = true;
            } else {
                this.hasErrors = false;
            }

            this.showAllErrorsContainer = true;
        } catch (e) {
            if (Array.isArray(e)) {
                this.addNotification(this.$t('Error occured'), e[0] as string, 'error');
            }
        }
    }

    private async calculateConfiguratorPrice(productFormValues: ActiveProductFormValueObject) {
        this.pIdFieldErrors = [];
        // const offerItemId = this.$route.params.offerItemId;

        if (this.currentProductForm == null) {
            this.sellingPriceList = [];
            return;
        }

        let sellingPriceList: PriceItem[] = [];

        try {
            const prices = await this.calculatePrice({
                activeProductFormValues: productFormValues,
                productFormId: this.currentProductForm.id,
                clientId: this.clientId,
                currencySymbol: this.currencySymbol,
                offerItemId: this.offerItemId ?? undefined,
                includePurchasePrice: true,
                projectId: this.projectId,
            });

            sellingPriceList = prices.sellingPrices;
            this.purchasePriceList = prices.purchasePrices;
            this.roltekPriceList = prices.roltekPrices;
        } catch (e) {
            if (Array.isArray(e)) {
                this.addNotification(this.$t('Error occured'), e[0] as string, 'error');
            }
        }

        this.sellingPriceList = sellingPriceList;
        this.showAllErrorsContainer = false;
    }

    private async getCurrentProductForm(): Promise<ProductForm | null> {
        const productFormId = this.wizardData
            ? this.wizardData.productFormId
            : String(this.$route.params.productFormId);

        try {
            await this.getSingleProductForm(productFormId);
        } catch (e) {
            const errorObj = e as ErrorOptions;

            this.addNotification(
                this.$t('Error occured'),
                errorObj.response ? errorObj.response.data.meta.message : (errorObj.message as string),
                'error'
            );

            this.loadingOverlay.stop();
            this.$router.push({ name: RouteNames.error });
            return null;
        }

        return this.productForm(productFormId);
    }

    private async getProperRouteOfferItem(routeOfferItemId: string): Promise<void> {
        try {
            await OfferItem.getById(routeOfferItemId);
        } catch (e) {
            this.loadingOverlay.stop();

            const errorObj = e as ErrorOptions;

            this.addNotification(
                this.$t('Error occured'),
                errorObj.response ? errorObj.response.data.meta.message : (errorObj.message as string),
                'error'
            );

            this.$router.back();
        }

        return Promise.resolve();
    }

    private updateUnsavedDataState({ state }: { state: boolean }) {
        this.hasUnsavedData = state;
    }

    private addNotification(title: string, description: string, errorType: 'error' | 'warning') {
        this.notifications.push({
            title: this.$t(title),
            description: this.$t(description),
            errorType,
        });
    }

    private removeNotification(title: string) {
        const notificationIndex = this.notifications.findIndex((notification) => {
            notification.title === 'title';
        });

        this.notifications.splice(notificationIndex, 1);
    }

    private setConfiguratorLevel() {
        const expertUserGroups = ['1', '2', '3'];

        if (expertUserGroups.includes(String(this.userGroup))) {
            this.level = 2;
        } else {
            this.level = 0;
        }
    }

    private validateFormFields() {
        if (!this.activeProductFormId && this.activeProductFormId !== 0) {
            return;
        }

        this.formFieldErrorsExist = false;
        // Get visible fields and validate their values
        const formValues: FormValidationPayload[] = [];

        const rowValues = this.getProductFormValues(this.activeProductFormId);

        formValues.push({
            rowKey: null,
            values: rowValues ?? {},
            fieldSegments: this.flattenedFields as any,
        });

        const errorState = checkForErrorsInFieldsAndReturnState(formValues);

        const hasErrors = errorState.hasErrors;
        this.formFieldErrorsExist = hasErrors;

        if (hasErrors) {
            this.showErrorState(errorState.errorDescription);
        }
        EventBus.$emit(EventBusEvents.formValidationFinished);
    }

    private showErrorState(description: string) {
        this.$notification.error({
            message: this.$t('Invalid characters detected in form fields:') as string,
            description: description as string,
        });
    }

    private async mounted() {
        resetHelperState();
        // const offerItemId = this.$route.params.offerItemId;
        const productId = this.wizardData ? this.wizardData.productId : this.$route.params.productId;
        const productSystemName = this.wizardData
            ? this.wizardData.productSystemName
            : this.$route.query.productSystemName;
        this.clientId = this.wizardData ? this.wizardData.clientId : (this.$route.query.clientId as string | null);
        this.offerId = this.wizardData ? this.wizardData.offerId : (this.$route.query.offerId as string);
        this.offerItemId = this.wizardData ? this.wizardData.offerItemId : this.$route.params.offerItemId;
        this.projectId = this.wizardData ? '' : (this.$route.query.projectId as string);

        this.setConfiguratorLevel();

        if (this.wizardData) {
            this.level = -1;
        }

        this.loadingOverlay.start();

        EventBus.$on(EventBusEvents.changesInDataMade, this.updateUnsavedDataState);

        if (this.functions.length <= 0) {
            try {
                await JsFunction.getAll();
            } catch (e) {
                return e;
            }
        }

        if (this.clientId != null && !this.client) {
            try {
                await Client.getByIdForConfigurator(this.clientId);
            } catch (error) {
                return;
            }
        }

        if (this.offerId && !this.offer) {
            try {
                await Offer.getById(this.offerId);
            } catch (e) {
                return;
            }
        }

        if (this.offerItemId && !this.offerItem) {
            try {
                await this.getProperRouteOfferItem(this.offerItemId);
            } catch (e) {
                return;
            }
        }

        this.currentProductForm = await this.getCurrentProductForm();

        if (this.currentProductForm == null) {
            return;
        }

        // If this is provided, call has been made from dimensions wizard and data should be present in the store
        if (this.$route.params.alreadyInStore && this.$route.params.alreadyInStore !== null) {
            this.activeProductFormId = parseInt(this.$route.params.alreadyInStore, 10);
        } else {
            // otherwise, just add new active product form
            if (this.offerItem != null) {
                this.isEditMode = true;
                this.activeProductFormId = await this.addActiveProductForm(
                    await extractDefaultValues({
                        productForm: this.currentProductForm,
                        productId,
                        clientId: this.clientId,
                        productSystemName, // this.$route.query.productSystemName,
                    })
                );
                await mapOfferItemsFormData(
                    this.offerItem.offerItemFormData,
                    this.activeProductFormId as number,
                    this.clientId
                );
            } else {
                this.activeProductFormId = await this.addActiveProductForm(
                    await extractDefaultValues({
                        productForm: this.currentProductForm,
                        productId,
                        clientId: this.clientId, // this.$route.query.clientId as string | null,
                        productSystemName, // this.$route.query.productSystemName,
                    })
                );
            }
            this.$emit('activeProductFormIdChanged', this.activeProductFormId);
        }
        this.updateFunctions = extractUpdateFunctions(this.currentProductForm);
        const customValues = this.$store.getters['configurator/customValuesOverride'];

        if (this.wizardData) {
            await this.$nextTick();
            this.setCustomFieldValues(this.wizardData?.customFieldValues);
        } else if (customValues.length) {
            await this.$nextTick();
            this.setCustomFieldValues(customValues);
        }

        this.setDefaultValueWarnings();
        this.loadingOverlay.stop();

        EventBus.$on(EventBusEvents.updateSystemColorValue, this.updateColorValue);
    }

    private setCustomFieldValues(customValues: any) {
        if (this.activeProductFormId == null) {
            return null;
        }

        customValues.forEach((field: any) => {
            this.updateActiveProductFormValue({
                pId: field.pId,
                value: field.value,
                productFormId: this.activeProductFormId as number,
            });
        });
    }

    private updateColorValue(payload: {
        activeProductFormId: number;
        fieldToBeUpdatedPID: string;
        fieldValue: string;
        shouldFocusManualEntryField: boolean;
        manualEntryFieldValue: string | null;
    }) {
        if (payload.activeProductFormId !== this.activeProductFormId) {
            return;
        }

        this.updateActiveProductFormValue({
            pId: payload.fieldToBeUpdatedPID,
            value: payload.fieldValue,
            productFormId: payload.activeProductFormId,
        });

        if (payload.manualEntryFieldValue) {
            this.$nextTick(() => {
                this.updateActiveProductFormValue({
                    pId: 'p74022',
                    value: payload.manualEntryFieldValue as string,
                    productFormId: payload.activeProductFormId,
                });
            });
        }
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.changesInDataMade, this.updateUnsavedDataState);
        EventBus.$off(EventBusEvents.updateSystemColorValue, this.updateColorValue);

        this.notifications = [];
        this.$notification.destroy();

        // Remove warnings for invalid default values
        this.$store.dispatch('configurator/setDefaultValueWarnings', []);

        if (!this.shouldOpenDimensionsWizardConfigurator) {
            this.$store.dispatch('configurator/setCustomValuesOverride', []);
        }

        if (!this.wizardData) {
            // if in wizard, data in store is still needed
            this.resetActiveProductForms();
        }
    }

    @Watch(`productFormValues`, { deep: true, immediate: true })
    private onProductFormValueChange(productFormValues: ActiveProductFormValueObject) {
        this.$nextTick(() => {
            this.updateFunctions.forEach((entry: UpdateFunctionEntry) => {
                if (this.activeProductFormId == null) {
                    return;
                }
                const updatedValues: ConfiguratorValue[] = extractUpdateFunctionValues(
                    entry.updateFunctions,
                    entry.type,
                    productFormValues
                );
                if (updatedValues.length > 0) {
                    if (!this.checkIfSelectedValueIsValid(entry.id, updatedValues[0])) {
                        return;
                    }

                    this.updateActiveProductFormValue({
                        pId: entry.id,
                        value: updatedValues[0],
                        productFormId: this.activeProductFormId,
                    });
                }
            });
            this.debounceTriggerVisibilityFunctions();

            this.debounceTriggerOptionsFunctions();

            // Ignore calculations if in dimensions wizard
            if (!this.wizardData) {
                // @ts-ignore
                this.debouncedPriceCalculation(productFormValues);
            }

            this.debounceShowingChecklistErrors();
        });
    }

    private checkIfSelectedValueIsValid(id: string, value: ConfiguratorValue) {
        // We return false only if selected value was not in dropdown options at start and is missing right now
        if (!this.defaultValueWarnings) {
            return true;
        }

        const field = this.flattenedFields.find((field) => field.pId === id);

        if (!field) {
            return true;
        }

        if (field.fieldType !== 'dropdown') {
            return true;
        }

        const isValueAvailable = field.checklistDropdownField?.checklistDropdownOptions.some((dropdownOption: any) => {
            return dropdownOption.vId === value;
        });

        if (isValueAvailable) {
            return true;
        }

        if (!this.defaultValueWarnings) {
            return true;
        }

        const invalidValueWasDetectedAtMount = this.defaultValueWarnings[0].warnings.some(
            (warning: any) => warning.pId === id
        );

        return !invalidValueWasDetectedAtMount;
    }

    private showChecklistErrors() {
        this.notifications = [];
        this.$notification.destroy();

        for (const checklistField of this.checklistErrors) {
            window.setTimeout(() => {
                if (checklistField.checklistStringField && checklistField.checklistStringField.defaultValue) {
                    switch (checklistField.fieldType) {
                        case 'abort':
                            this.addNotification(
                                'Error happened',
                                parseAndTransformStringWithDynamicValues(
                                    checklistField.checklistStringField.defaultValue,
                                    checklistField.checklistStringField.functions,
                                    this.productFormValues,
                                    'function'
                                ),
                                'error'
                            );

                            break;
                        case 'warning':
                            this.addNotification(
                                'Warning',
                                parseAndTransformStringWithDynamicValues(
                                    checklistField.checklistStringField.defaultValue,
                                    checklistField.checklistStringField.functions,
                                    this.productFormValues,
                                    'function'
                                ),
                                'warning'
                            );
                            break;
                    }
                }
            }, 50);
        }
    }

    private async closeProductView() {
        this.$confirm({
            title: this.$t('Warning'),
            content: this.$t('Are you sure you want to close product view?'),
            okText: this.$t('Close'),
            cancelText: this.$t('Cancel'),
            onOk: () => {
                this.$router.back();
            },
            onCancel() {},
        });
    }

    private closeModal() {
        this.showDefaultValueWarningsModal = false;
    }

    @Watch('functions')
    private onFunctionsChange() {
        registerJsonLogicFunctions(this.functions);
    }
}
