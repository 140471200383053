
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ChecklistFloatField } from '@/interfaces/components/configurator/ChecklistFloatField';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { Getter } from 'vuex-class';
import { EventBus } from '@/helpers/EventBusHelper';

@Component({
    name: 'ChecklistFloatFieldModule',
})
export default class ChecklistFloatFieldModule extends Vue {
    @Prop({ required: true }) public checklistFloatField!: ChecklistFloatField;
    @Prop({ required: true }) private pId!: string;
    @Prop({ default: false }) private isDisabled!: boolean;
    @Prop({ default: null }) private debounceToggleIsActive!: ((state: boolean) => void) | null;
    @Prop() private activeProductFormId!: number | null;
    @Getter('configurator/activeProductFormValue') private productFormValue!: (
        pId: string,
        productFormId: number
    ) => number | null;

    private value = 1;

    private get fieldValue() {
        if (this.activeProductFormId == null) {
            if (this.checklistFloatField.min < 0) {
                return 0;
            }

            return this.checklistFloatField.min;
        }

        return this.productFormValue(this.pId, this.activeProductFormId);
    }

    private onBlur() {
        if (this.value === null) {
            this.onChange(1);
        }

        if (this.value !== this.fieldValue) {
            this.onChange(this.value);
        }

        this.debounceToggleIsActive && this.debounceToggleIsActive(false);
    }

    private onFocus() {
        window.setTimeout(() => {
            this.debounceToggleIsActive && this.debounceToggleIsActive(true);
        }, 200);
    }

    private onChange(newValue: number) {
        EventBus.$emit(EventBusEvents.changesInDataMade, { state: true });

        this.$emit(EventBusEvents.updateStoreFieldValue, {
            pId: this.pId,
            value: Number(newValue),
            productFormId: this.activeProductFormId,
        });
    }

    @Watch(`fieldValue`, { immediate: true, deep: true })
    private onUpdateFieldValue(newValue: number) {
        this.value = newValue;
    }
}
